import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import { CareersType } from '../../../interfaces/frontpage';
import { SectionHeadline, SectionTitle } from '../../../styles/Common';
import { linkHelper } from '../../../utils/linkHelper';
import { CareersText, JobsButton, SectionContainer } from './CareersStyle';

const Careers: FC<CareersType> = ({ headline, description, link, link_label, title }) => {
    const { t } = useTranslation();
    const { language } = useI18next();

    return (
        <SectionContainer>
            <SectionHeadline>{headline !== '' ? headline : t('careersSectionHeadline')}</SectionHeadline>
            <SectionTitle>{title}</SectionTitle>
            <CareersText>{description}</CareersText>
            {link && link_label && <JobsButton to={linkHelper(link, language)}>{link_label}</JobsButton>}
        </SectionContainer>
    );
};

export default Careers;
